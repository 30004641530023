.scoreboard-card {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  color: #1d3557;
  text-align: left;
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
}

.scoreboard-card:hover {
  transform: translateY(-5px) scale(1.03);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.flag-media {
  border-radius: 12px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.rank {
  font-size: 1.25rem;
  margin-bottom: 8px;
  color: #1d3557;
}

.country {
  font-size: 1rem;
  margin-bottom: 4px;
  color: #457b9d;
}

.votes {
  font-size: 0.9rem;
  color: #a8dadc;
}
